/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */


.mat-dialog-container .mat-dialog-content {
    max-height: calc(100vh - 220px) !important;
}

.inventory-advanced-overlay .mat-dialog-container,
.sidemark-advanced-overlay .mat-dialog-container,
.job-advanced-overlay .mat-dialog-container {
    padding: 0;
}

.inventory-advanced-overlay .mat-mdc-dialog-surface,
.sidemark-advanced-overlay .mat-mdc-dialog-surface,
.job-advanced-overlay .mat-mdc-dialog-surface {
    padding: 0;
    border-radius: 0 !important;
}

.inventory-advanced-overlay .mat-mdc-tab-group .mat-mdc-tab-body-content,
.bringpro-sidemarks .mat-mdc-tab-group .mat-mdc-tab-body-content {
    padding: 0 !important;
}

.bringpro-primary mat-card-header,
.bring-green-linear {
    background: linear-gradient(1deg, rgba(20, 56, 71, 1) 0%, rgba(28, 78, 101, 1) 100%);
    outline-color: white;
}

.mat-mdc-card-header-text {
    width: 100%;
}

.mat-mdc-menu-content {
    padding: 0 !important;
}

.responsive-drawer-button {
    margin-left: -8px !important;
    margin-top: 4.6rem !important;
}

.avatar-image img {
    height: 2.5rem !important;
}

.word-wrap-custom {
    display: inline-block;
    word-break: break-word;
}


.tip-container .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle {
    border-color: white !important;
}

.tip-container .mat-mdc-select-arrow svg,
.tip-container .mat-mdc-select-value-text,
.tip-container input,
.tip-container label,
.tip-container mat-label {
    color: white !important;
}

.tip-container .mat-button-toggle-group> :not([hidden])~ :not([hidden]) {
    // margin: 0 !important;
}

.tip-container .mat-button-toggle-group .mat-button-toggle {}

@media (max-width: 767px) {
    .responsive-drawer-button {
        margin-left: -2px !important;
        margin-top: 3.2rem !important;
    }
}

@media print {
    @page {
        margin: 30px;
    }
}

button.cta-main {
    @apply bg-gradient-to-r from-orange-600 shadow to-orange-400 border-solid border-2 border-white font-bold py-2 px-4 rounded-full text-white #{'!important'}
}

button.cta-secondary {
    @apply bg-white shadow border border-solid border-orange-500 font-bold py-2 px-4 rounded-full text-orange-500 #{'!important'}
}
.float-end {
    float:inline-end
}

.mat-drawer-inner-container {
    height: auto !important;
}

.mat-mdc-button-touch-target {
    height: 30px !important;
    width: 30px !important;
}

.upload-image {
    .ngx-file-drop__drop-zone {
        border: 1px solid #94a3b8  !important;
        border-radius: 6px !important;
        height: 144px !important;
        background-color: white !important;
        &.ngx-file-drop__drop-zone--over {
            background-color: transparent !important;
            border: 1px solid rgb(28 78 101) !important;
        }
        .ngx-file-drop__file-input {
            opacity: 0;
            width: 100%;
            display: block;
        }
    }
    .preview-img {
        border-radius: 10px;
        height: 100px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
        }
    }
    .mat-icon {
        min-width: unset;
        min-height: unset;
        height: 20px;
        width: 20px;
    }
}

.ngx-file-drop__drop-zone {
    border: none !important;
}

.media-uploader {
    .ngx-file-drop__drop-zone {
        height: 180px !important;
    }
}

.scan-region-highlight {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%,-50%) !important;
}

/* Chats */

app-chat {
    height: 100%;
}

.new-group {
    .ngx-file-drop__drop-zone, .ngx-file-drop__content {
        height: auto !important;
    }
}

.emoji-mart {
    position: absolute;
    left: 5px;
    bottom: 80px;
    z-index: 999;
    @media screen and (max-width: 567px) {
        width: calc(100% - 10px) !important;
        margin: 0 auto !important;
    }
}

.fadeInBottom { 
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: fadeInBottom;
    display: inline-block;
}

@keyframes fadeInBottom {
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.chat-preview-image-box {
    border-radius: 15px 15px 0 0;
    border: 2px solid;
    border-bottom: 0;
    @apply border-gray-300 bg-white #{'!important'};
}

.chat-message-box {
    .mat-mdc-text-field-wrapper {
        border-radius: 0 0 15px 15px !important;
    }
    &.mat-mdc-form-field.mat-form-field-appearance-fill {
        &.mat-focused:not(.mat-form-field-invalid) {
            .mat-mdc-text-field-wrapper {
                @apply border-gray-300 #{'!important'};
            }
        }
    }
}

// Mat drawer scroll on print
.mat-drawer-scroll {
    position: absolute !important;
    @media print {
        position: relative !important;
        .mat-drawer-content {
            overflow: unset !important;
        }
    }
}

.columns-attachment {
    .mat-mdc-select-min-line {
        max-width: 480px;
    }
}

auth-sign-up {
    height: 100%;
}

.bg-white-gradient {
    background: rgba(255, 255, 255, 0.7);
}

.mat-drawer-container-has-open {
    &.iphone-drawer-opened {
       position: fixed !important;
    }
}

.tenant-stepper {
    .mat-horizontal-stepper-header-container {
        padding: 16px !important;
    }
    .mat-horizontal-content-container {
        padding: 0 !important;
    }
    .mat-vertical-content {
        margin-top: 16px !important;
    }
    @media (max-width: 600px) {
        .mat-vertical-content-container {
            margin-left: 0 !important;
        }
    }
}

.circle-picker {
    margin: 10px 0 0 0 !important;
}

.raised-wrap {
    .zDepth-1 {
        box-shadow: unset !important;
    }
    .material-picker {
        width: 100% !important;
        height: auto !important;
        padding: 0 !important;
        font-family: unset !important;
        .material-split {
            display: none !important;
        }
    }
}

.add-room-button {
    custom-formly-field-button {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.room-select {
    formly-field-mat-select {
        width: 100%;
    }
}